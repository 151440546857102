import * as React from 'react';
import {Button} from "@mui/material";
import "./NavBarButton.css";

export default function NavBarButton(props) 
{
  return (
    <Button underline="none" onClick={props.onClick} className={props.className} sx={{  transition: "0.1s",
      ':hover': {
        color: 'primary.blue',
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
      }, fontSize: {lg:"0.9vw", md:"1.5vw", xs:"2.5vh"}  }}><strong>{props.text}</strong></Button>
  );
}